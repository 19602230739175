import React from "react"

import "./contact-form.scss"

export default class ContactForm extends React.Component {
  contactForm = React.createRef()
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    isSubmitting: false,
    showSuccess: false,
    showWarning: false,
    showError: false,
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = async event => {
    event.preventDefault()

    this.setState({
      isSubmitting: true,
      showSuccess: false,
      showWarning: false,
      showError: false,
    })

    const form = this.contactForm.current
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({
          "form-name": form.getAttribute("name"),
          ...this.state,
        }),
      })

      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        showSuccess: true,
      })
    } catch (error) {
      this.setState({
        showError: true,
      })
    }

    this.setState({
      isSubmitting: false,
    })
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  render() {
    return (
      <form
        ref={this.contactForm}
        name="contact"
        onSubmit={this.handleSubmit}
        method="post"
        className="form-contact"
        data-netlify="true"
      >
        <p className="form-description">
          Je te félicite de prendre ce temps pour toi et de vouloir t’offrir le
          meilleur pour ta vie future. Le plus dur n’est pas de travailler sur
          toi mais de <strong>faire ce premier pas</strong> : ce choix est au
          bout de tes doigts.
        </p>
        <p className="wrapper">
          <label>
            <strong>◉ Ton nom </strong>
            <br />
            <span className="form-control-wrap">
              <input
                type="text"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleInputChange}
                required
              />
            </span>
          </label>
          <label>
            <strong>◉ Ton prénom </strong>
            <br />
            <span className="form-control-wrap">
              <input
                type="text"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleInputChange}
                required
              />
            </span>
          </label>
        </p>
        <p className="wrapper">
          <label>
            <strong>◉ Ton adresse courriel</strong>
            <br />
            <span className="form-control-wrap">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
            </span>
          </label>
          <label>
            <strong>◉ Ton numéro de téléphone </strong>
            <br />
            <span className="form-control-wrap">
              <input
                type="text"
                name="phone"
                value={this.state.phone}
                onChange={this.handleInputChange}
              />
            </span>
          </label>
        </p>
        <p>
          <label>
            <strong>
              ◉ Parle moi de toi et dis moi quelle(s) sont les raison(s) et les
              problématique(s) dans ta vie actuelle te bloquent pour aspirer à
              une vie meilleure ?
            </strong>
            <span className="form-control-wrap">
              <textarea
                name="message"
                cols="40"
                rows="10"
                value={this.state.message}
                onChange={this.handleInputChange}
                required
              ></textarea>
            </span>
          </label>
        </p>
        <p>
          <button type="submit" disabled={this.state.isSubmitting}>
            Envoyer
          </button>
          {this.state.isSubmitting && <span className="ajax-loader"></span>}
        </p>
        {this.state.showSuccess && (
          <div className="form-submission is-active success">
            We received your submission, thank you!
          </div>
        )}
        {this.state.showWarning && (
          <div className="form-submission is-active warning">
            We received your submission, thank you!
          </div>
        )}
        {this.state.showError && (
          <div className="form-submission is-active error">
            Une erreur est survenue veuillez réessayer
          </div>
        )}
      </form>
    )
  }
}
