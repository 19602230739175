import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import ContactForm from "../components/contact-form/contact-form"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Contact() {
  return (
    <>
      <SEO title={"Contact"} keywords={[`contact`]} />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader title="Contact" header="/images/headers/8.jpg" />
        <Layout>
          <h2 class="at-page__title">PREMIER CONTACT ENSEMBLE</h2>
          <div class="at-page__title-divider"></div>
          <ContactForm />
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
